require("@rails/ujs").start()
require("@rails/activestorage").start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
document.addEventListener('DOMContentLoaded', function() {
  const navToggle = document.querySelector('.nav-toggle');
  const navToggleIcon = document.querySelector('.nav-toggle-icon');
  const navList = document.querySelector('.nav__list');
  const nav = document.querySelector('.nav');
  let navHeight = nav.scrollHeight;
  const modal = document.querySelector('.c-mobile-modal');
  const svg = document.querySelector('#ppfc-svg');
  let navActive = false;

  function setNavHeight() {
    navHeight = nav.scrollHeight;
  }
  
  navToggle.addEventListener('click', function() {
    this.classList.toggle('is-active');
    navToggleIcon.classList.toggle('is-active');
    navList.classList.toggle('is-active');
    
    if (navActive == false) {
      nav.style.maxHeight = navHeight + 'px';
      modal.classList.add('is-visible');
      navActive = true;
    } else if (navActive == true) {
      nav.style.maxHeight = '0px';
      modal.classList.remove('is-visible');
      navActive = false;
    }
  });
  
  modal.addEventListener('click', function() {
    if (navActive == true) {
      navToggle.classList.remove('is-active');
      navToggleIcon.classList.remove('is-active');
      navList.classList.remove('is-active');
      nav.style.maxHeight = '0px';
      this.classList.remove('is-visible');
      navActive = false;
    } else {
      return null;
    }
  });

  setNavHeight();

  window.addEventListener('resize', _.debounce(setNavHeight, 200));

  /**
   * SVG Rotate Animation on homepage
   * 
   * Detects scrolling and uses scrollY value with a fixed dampening
   * constant to create a rotate transform on the PPFC logo.
   * 
  */

  let latestKnownScrollY = 0;
  let ticking = false;
  const rotationConstant = 0.15;

  function rotate() {
    ticking = false;
    if(svg) {
      svg.style.transform = `rotate(-${latestKnownScrollY * rotationConstant}deg)`;
    }
  }

  function requestTick() {
    if(!ticking) {
      requestAnimationFrame(rotate);
    }
    ticking = true;
  }

  function onScroll() {
    latestKnownScrollY = window.scrollY;
    requestTick();
  }

  window.addEventListener('scroll', onScroll, false);
});
